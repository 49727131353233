import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "backoffice/user"; 

export const BackofficeUserAuth = async (email, password) => {
  const criterion = {
    loginEmail: email,
    loginPassword: password
  };
  return new Promise((resolve, reject) => {
    axios
      .post(proxyServer + "/auth", { criterion })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};


export const BackofficeUserAuthCheck = async (userToken) => {
    const criterion = { userToken };
    return new Promise((resolve, reject) => {
      axios
        .post(proxyServer + "/token-auth", { criterion })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

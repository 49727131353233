import React, { Component } from "react";
import { Button, Row, Card, CardBody, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import { VehiclesGetDaySummary } from "../../api-tasks-analytics/vehicles";
import DateRangePicker from "../../components/DateRangePicker";
import { NotificationManager } from "../../components/common/react-notifications";
import DetailsTab from "../day-summary/details-tab";
import MapModel from "../../components/MapModel";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
  AppHelperDurationSecToHHmm,
} from "../../helpers/AppHelper";

const pagingLimit = -1;

class Idle extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicles: [],
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - alerts.csv",
      mapModleGeo: "",
      mapModlePlace: "",
      mapModelOpen: false,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  toggleLarge = () => {
    this.setState((prevState) => ({
      mapModelOpen: !prevState.mapModelOpen,
    }));
  };

  showModel = (geo, place) => {
    this.setState({
      mapModleGeo: geo,
      mapModlePlace: place,
      mapModelOpen: true,
    });
  };

  findDriverName = (driverId) => {
    if (driverId && driverId > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === driverId;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicleId && ignition.vehicleId > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicleId;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  onDateRangeGo = (limit, skip) => {
    if (!this.state.selectedVehicles || !this.state.selectedVehicles.length) {
      NotificationManager.error(
        "Please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const vehicleIds = this.state.selectedVehicles.map((el) => {
      return el.key;
    });

    this.setState({ loading: true });

    VehiclesGetDaySummary(user.userToken, vehicleIds, startTime, endTime).then(
      (response) => {
        this.setState({
          loading: false,
          allRows: [],
          totalCount: 0,
          fileName: `Ctraac - day summary, ${this.state.startTime.format(
            "YYY-MM-DD hh:mm A"
          )} to ${this.state.endTime.format("YYY-MM-DD hh:mm A")}.csv`,
        });

        if (response && Array.isArray(response) && !response.error) {
          const a = response.map((x) => {
            const summaryDay = moment(x.summaryDay);
            const summaryDayText = moment(x.summaryDay).format("ddd MMM-DD");
            const vehicleName = this.findVehicleName(x);

            // format('dd DD-MMM-YYYY')
            const errands = x.errands.map((y) => {
              const startTime = moment(y.startTime);
              const endTime = moment(y.endTime);

              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format("hh:mm A"),
                endTimeText: endTime.format("hh:mm A"),
                dayDiff: endTime.diff(x.summaryDay, "days") || 0,
                distance: y.distance.toFixed(1),
                duration: AppHelperDurationAsHHmm(
                  startTime.toDate(),
                  endTime.toDate()
                ),
                driverName: this.findDriverName(y.driverId),
              };
            });

            const idles = x.idles.map((y) => {
              const startTime = moment(y.startTime);
              const endTime = moment(y.endTime);

              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format("hh:mm A"),
                endTimeText: endTime.format("hh:mm A"),
                dayDiff: endTime.diff(x.summaryDay, "days") || 0,
                duration: AppHelperDurationAsHHmm(
                  startTime.toDate(),
                  endTime.toDate()
                ),
                driverName: this.findDriverName(y.driverId),
                time: new Date(y.startTime),
              };
            });

            const stops = x.stops.map((y) => {
              const startTime = moment(y.startTime);
              const endTime = moment(y.endTime);

              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format("hh:mm A"),
                endTimeText: endTime.format("hh:mm A"),
                dayDiff: endTime.diff(x.summaryDay, "days") || 0,
                duration: AppHelperDurationAsHHmm(
                  startTime.toDate(),
                  endTime.toDate()
                ),
                driverName: this.findDriverName(y.driverId),
              };
            });

            const overSpeeds = x.overSpeeds.map((y) => {
              const startTime = moment(y.eventTime);

              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format("hh:mm:ss A"),
                driverName: this.findDriverName(y.driverId),
                totalSeconds: y.totalSeconds > 15 ? "15+" : y.totalSeconds,
              };
            });

            return {
              ...x,
              summaryDay,
              summaryDayText,
              distance: (x.distance || 0).toFixed(1),
              enginHours: AppHelperDurationSecToHHmm(x.enginHours),
              idleHours: AppHelperDurationSecToHHmm(x.idleHours),
              parkingHours:
                x.parkingHours === 86400
                  ? "24:00"
                  : AppHelperDurationSecToHHmm(x.parkingHours),
              firstStartTime: x.firstStart
                ? moment(x.firstStart).format("hh:mm A")
                : "-",
              lastEndTime: x.lastEnd
                ? moment(x.lastEnd).format("hh:mm A")
                : "-",
              dayDiff: moment(x.lastEnd).diff(summaryDay, "days") || 0,
              vehicleName,
              errands,
              idles,
              stops,
              overSpeeds,
            };
          });

          this.setState({
            allRows: a,
            totalCount: response.count,
            touched: true,
          });
        }
      }
    );
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
    });
    if (this.state.selectedVehicles.length) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <MapModel
          geo={this.state.mapModleGeo}
          place={this.state.mapModlePlace}
          isOpen={this.state.mapModelOpen}
          closeButton={
            <Button
              color="primary"
              outline
              size="xs"
              className="mb-2"
              onClick={this.toggleLarge}
            >
              <IntlMessages id="common.close" />
            </Button>
          }
          toggle={this.toggleLarge}
        />

        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="6" lg="6" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      startTime={moment().startOf("month")}
                      suggestions="months"
                      dateOnly={true}
                    />
                  </Colxx>
                </Row>

                <Row>
                  <Colxx xxs="12" className="mb-1">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                      dateOnly={true}
                    />
                  </Colxx>
                </Row>

                <Row>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {this.state.selectedVehicles.length && this.state.touched ? (
                  <CardTitle>
                    {intl.formatMessage({
                      id: "day-summary",
                    })}{" "}
                    :{" from "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}
                    {" to "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    {" "}
                    {intl.formatMessage({
                      id: "day-summary.search",
                    })}{" "}
                  </CardTitle>
                )}

                {!this.state.allRows ? (
                  <p>
                    {intl.formatMessage({
                      id: "day-summary.nothing",
                    })}{" "}
                  </p>
                ) : (
                  ""
                )}
                <div className="w-100 ">
                  <small className="float-right">
                    {" "}
                    00:00 = HH:mm, HH = Hour, mm = Minuts
                  </small>
                  <div className="clearfix"> </div>
                </div>
                <div className="card-body-min-height d-block">
                  <DetailsTab
                    allRows={this.state.allRows}
                    onShowMapModel={this.showModel}
                  />
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Idle);

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import { addSurveyItem } from "../../redux/actions";
import { ProductAdd } from "../../api-backoffice/products";
import { NotificationManager } from "../../components/common/react-notifications";

const ProductTypes = [
  { label: "Product", value: 1, key: 1 },
  { label: "Subscriptions", value: 2, key: 2 },
];

const ProductWarranty = [
  { label: "-", value: 1, key: 1, inDays: "0" },
  {
    label: "1 Month",
    value: 2,
    key: 2,
    inDays: 30,
    priorWarning: 4,
    suspendAfter: 4,
  },
  {
    label: "3 Months",
    value: 3,
    key: 3,
    inDays: 90,
    priorWarning: 7,
    suspendAfter: 7,
  },
  {
    label: "1 Year",
    value: 4,
    key: 4,
    inDays: 365,
    priorWarning: 30,
    suspendAfter: 30,
  },
  {
    label: "2 Year",
    value: 5,
    key: 5,
    inDays: 730,
    priorWarning: 45,
    suspendAfter: 45,
  },
];

class ProductAddNewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productWarranty: ProductWarranty[0],
      productPrice: "0",
      productName: "",
      productType: ProductTypes[0],
      productDetails: "",
    };
  }

  clearForm = () => {

    console.log('clear form called ');
    this.setState({
      productWarranty: ProductWarranty[0],
      productPrice: "0",
      productName: "",
      productType: ProductTypes[0],
      productDetails: "",
    });
  };

  addNetItem = () => {
    const {
      productType,
      productName,
      productPrice,
      productDetails,
      productWarranty,
    } = this.state;
    const user = this.props.user;

    const attributes = {
      productWarranty : productType.value === 1? productWarranty : null ,
      productRecurring : productType.value === 2? productWarranty : null
    }

    ProductAdd(
      user.userToken,
      productType,
      productName,
      productPrice, // unitPrice,
      productDetails,
      attributes
    ).then((response) => {
      if (response) {
        this.setState({ updating: false });
        if (response) {
          if (response.status === "success") {
            this.clearForm();

            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
            // this.props.driverSelectRequest(this.props.history);
          } else {
            console.error("product.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
            this.props.authApiAccessCheck(this.props.history, response);
          }
        }
      }
    });
  };

  render() {
    const { modalOpen, toggleModal } = this.props;
    return (
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>Add New Product</ModalHeader>
        <ModalBody>
          <Label className="mt-2">Product Type</Label>
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            name="product_type"
            options={ProductTypes}
            value={this.state.productType}
            onChange={(val) => {
              this.setState({ productType: val });
            }}
          />

          <Label className="mt-2">
            {" "}
            {this.state.productType.value === 1 ? "Warranty" : "Recurring"}
          </Label>
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            options={ProductWarranty}
            value={this.state.productWarranty}
            onChange={(val) => {
              this.setState({ productWarranty: val });
            }}
          />

          <Label className="mt-2">Product Name</Label>
          <Input
            type="text"
            value={this.state.productName}
            onChange={(event) => {
              this.setState({ productName: event.target.value });
            }}
          />

          <Label className="mt-2">Product Price</Label>
          <Input
            type="number"
            placeholder="Enter unit price"
            defaultValue={"0"}
            value={this.state.productPrice}
            onChange={(event) => {
              this.setState({ productPrice: event.target.value });
            }}
          />

          <Label className="mt-2">Product Details</Label>
          <Input
            type="textarea"
            value={this.state.productDetails}
            onChange={(event) => {
              this.setState({ productDetails: event.target.value });
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => this.addNetItem()}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ surveyListApp }) => {
  return {
    surveyListApp,
  };
};
export default connect(mapStateToProps, {
  addSurveyItem,
})(ProductAddNewModal);

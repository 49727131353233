import { SUBSCRIBER_SELECT_SUCCESS, SUBSCRIBER_SELECT_ALL_SUCCESS } from "../actions";

const INIT_STATE = {
    details: null,
    allList: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBSCRIBER_SELECT_SUCCESS:
      return { ...state, details: action.payload.subscriber };

    case SUBSCRIBER_SELECT_ALL_SUCCESS:
        return { ...state, allList: action.payload.subscriberList };

    default:
      return { ...state };
  }
};

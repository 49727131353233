import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";

import { connect } from "react-redux";
import { authApiAccessCheck } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";

import IntlMessages from "../../helpers/IntlMessages";
import ProductAddNewModall from "./ProductAddNewModal";

class ProductsContainer extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();

    this.state = {
      modalOpen: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  render() {
    const { modalOpen } = this.state;

    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Button
                  color="primary"
                  size="lg"
                  className="top-right-button mr-1"
                  onClick={this.toggleModal}
                >
                  <IntlMessages id="survey.add-new" />
                </Button>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <p>It is goes here</p>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <ProductAddNewModall
          toggleModal={this.toggleModal}
          modalOpen={modalOpen}
          user={this.props.user}
          authApiAccessCheck={this.props.authApiAccessCheck}
          driverSelectRequest={this.props.productSelectRequest}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  const userDetails = authUser.details;
  return {
    user,
    userDetails,
  };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  ProductsContainer
);

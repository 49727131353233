import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "backoffice/vehicles"; 

export const BackofficeVehicleSelectAll = userToken => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-all", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

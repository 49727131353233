import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";

import { connect } from "react-redux";
import { authApiAccessCheck } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import IntlMessages from "../../helpers/IntlMessages";

let client;
class ToolsContainer extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();

    this.state = {
      connectedStatus: false,
      recevedData: [],
      textSendValue: "hello"
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  disconnect = () => {
    try {
      if (client) {
        client.close();
        client = null;
      }
    } catch (error) {
      console.log("err");
    }
  };

  addList = (data, type) => {

    const tempArr = this.state.recevedData || [];
           

            if(type ==='>'){
              const dataObj = JSON.parse(data);
            tempArr.splice(0, 0, {
              datetime: new Date(dataObj.time),
              type,
              data: dataObj.data,
            });}else{
              tempArr.splice(0, 0, {
                datetime: new Date(),
                type,
                data: data,
              });
              
            }

            this.setState({ recevedData: tempArr });

  }

  toggleConnection = () => {
    try {
      if (client && client.readyState !== WebSocket.CLOSED) {
        return;
      }

      // client = new W3CWebSocket("ws://backoffice-api.tfmsworld.com/ws");
      client = new W3CWebSocket("ws://localhost:5004/ws");

      client.onerror = () => {
        console.log("Connection Error");
      };

      client.onopen = () => {
        console.log("WebSocket Client Connected");
        // this.handleOnConnect();
        this.setState({
          connectedStatus: true,
        });
        // sending initial settings
        client.send('####{"NoDataTimeOutInMin":10}#');
      };

      client.onclose = () => {
        console.log("Client Closed");
        this.setState({
          connectedStatus: false,
        });
      };

      client.onmessage = (e) => {
        // console.log("receved message > ", e.data);

        if (typeof e.data === "string") {
          try {
            console.log("Received: ", e.data);
            
            this.addList( e.data, '>');

          } catch (error) {
            console.log("error");
          }
        }
      };
    } catch (error) {
      console.log(error);
    }
  };

  onSendData = (e)=> {
    
    if (this.state.txtMessage && client && client.readyState !== WebSocket.CLOSED) {
    client.send( this.state.txtMessage );
    

    this.addList( this.state.txtMessage, '<');
    

    }

   

  }

  handleChange = (event) => {
    this.setState({txtMessage: event.target.value});
  }

  render() {
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Button
                  color="primary"
                  size="xs"
                  className="top-right-button mr-1"
                  onClick={this.toggleConnection}
                >
                  Connect
                </Button>

                <Button
                  color="danger"
                  size="xs"
                  className="top-right-button mr-1"
                  onClick={this.disconnect}
                >
                  Disconnect
                </Button>

                <p>
                  Terminal Status:
                  {this.state.connectedStatus ? "Connected" : "Disconnected"}
                </p>

               

                <textarea
                  type="text"
                  className="form-control"
                  name="textSendValue"
                  value={this.state.txtMessage}
                  onChange={this.handleChange}
                />

                 <Button
                  color="secondary"
                  size="xs"
                  className="top-right-button mt-1"
                  onClick={this.onSendData}
                >
                  Send
                </Button>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <ul>
                  {this.state.recevedData.map((item, i) => (
                    <li key={i}>
                      {item.datetime.toLocaleTimeString()} {item.type} {item.data}

                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  const userDetails = authUser.details;
  return {
    user,
    userDetails,
  };
};

export default connect(mapStateToProps, { authApiAccessCheck })(ToolsContainer);

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "backoffice/products";

export const ProductsGetAll = async (userToken) => {
  const criterion = { userToken };
  return new Promise((resolve, reject) => {
    axios
      .post(proxyServer + "/get-all", { criterion })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ProductAdd = ( userToken, productType, productName, unitPrice, description, attributes) => {

  
  const criterion = { userToken,  productType, productName, unitPrice, description, attributes };
  console.log('the ProductAdd',  criterion);

  return axios
    .post(proxyServer + "/add", {
      criterion
    })    
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

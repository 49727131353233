import React, { Component } from "react";
import { Button, Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelect from "../../components/VehicleSelect";
import { authApiAccessCheck } from "../../redux/actions";
import { LogAccessSelectByIdAndDay } from "../../api-tasks/log-access";
import DateRangePicker from "../../components/DateRangePicker";
import CsvDownloader from "../../components/CsvDownloader";
import { NotificationManager } from "../../components/common/react-notifications";
import KingwoIdentifierIsolate from "../../helpers/kingwo/identifier";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
} from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

const headers = [
  { label: "Time", key: "time" },
  { label: "Verse", key: "verse" },
  { label: "Readable", key: "readable" },
];

function getReadable(verse) {
  try {
    return JSON.stringify(KingwoIdentifierIsolate(verse));
  } catch (error) {
    return "";
  }
}

class LogAccess extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicle: {},
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,

      fileName: "Ctraac - log-access.csv",
    };
  }

  handleOnSelectedVehicleChange = (selectedVehicle) => {
    this.setState({ selectedVehicle });

    this.setState({
      selectedVehicle,
      allRows: [],
    });

    setTimeout(() => {
      this.onDateRangeGo();
    }, 10);
  };

  onDateRangeGo = () => {
    if (!this.state.selectedVehicle.key) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());

    this.setState({ loading: true });

    LogAccessSelectByIdAndDay(
      user.userToken,
      this.state.selectedVehicle.key,
      startTime
    ).then((response) => {
      this.setState({
        loading: false,
        fileName: `Ctraac - log access of ${this.state.startTime.format(
          "YYY-MM-DD"
        )}.csv`,
      });

      if (response && Array.isArray(response)) {
        const a = response.map((x) => {
          return {
            ...x,
            time: AppHelperDateAndTimeToString(x.timestamp),
            readable: getReadable(x.verse),
          };
        });

        this.setState({
          allRows: a,
          totalCount: response.count,
          touched: true,
        });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      allRows: [],
    });
    if (this.state.selectedVehicle.key) {
      setTimeout(() => {
        this.onDateRangeGo();
      }, 10);
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <VehicleSelect
                      onChange={this.handleOnSelectedVehicleChange}
                    />
                  </Colxx>

                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      startTime={moment()}
                      singleDay={true}
                      dateOnly={true}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.onDateRangeGo()}
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {this.state.selectedVehicle.key && this.state.touched ? (
                  <CardTitle>
                    <IntlMessages id="report.log-access-of"/>
                    {this.state.startTime.format("DD MM YYYY")}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    <IntlMessages id="tracking.try-search-filter-or-select-vehicle" />
                  </CardTitle>
                )}

                {this.state.touched && !this.state.allRows.length ? (
                  <p>
                    {" "}
                    <IntlMessages id="tracking.nothing-to-show" />
                  </p>
                ) : (
                  ""
                )}
                <div className="card-body-min-height">
                  <div className="float-right">
                    <CsvDownloader
                      headers={headers}
                      filename={this.state.fileName}
                      data={this.state.allRows}
                    />
                  </div>
                  {this.state.allRows.length +" "+ intl.formatMessage({
                      id: "geofence.records",
                    })}

                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th><IntlMessages id="report.time" /></th>
                        <th><IntlMessages id="report.verse" /></th>
                        <th><IntlMessages id="report.readable" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.allRows.map((item, i) => (
                        <tr key={i + 1}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.time}</td>
                          <td style={{ maxWidth: "200px" }}>{item.verse}</td>
                          <td style={{ maxWidth: "300px" }}>
                            {" "}
                            {item.readable}{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(LogAccess);

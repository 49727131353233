const data = [
  {
    id: "dashboards",
    icon: "iconsminds-shop-4",
    label: "menu.dashboards",
    to: "/app/dashboards",
    subs: [
       
      {
        icon: "simple-icon-control-play",
        label: "menu.welcome",
        to: "/app/dashboards/welcome",
      },
       
    ],
  },
  {
    id: "masters",
    icon: "iconsminds-shop-4",
    label: "menu.masters",
    to: "/app/masters",
    subs: [
       
      {
        icon: "simple-icon-control-play",
        label: "menu.master-products",
        to: "/app/masters/products",
      },
       
    ],
  },

  {
    id: "tools",
    icon: "iconsminds-shop-4",
    label: "menu.tools",
    to: "/app/tools",
    subs: [
       
      {
        icon: "simple-icon-control-play",
        label: "menu.tools-tcp-terminal",
        to: "/app/tools/tcp-terminal",
      },
       
    ],
  },
   
  {
    id: "support",
    icon: "simple-icon-support",
    label: "menu.support",
    to: "/app/support",
    subs: [
      {
        icon: "iconsminds-receipt-4",
        label: "menu.invoices",
        to: "/app/support/invoices",
      },
      {
        icon: "iconsminds-credit-card",
        label: "menu.jobcards",
        to: "/app/support/jobcards",
      },
      {
        icon: "simple-icon-refresh",
        label: "menu.services",
        to: "/app/support/services",
      },
      {
        icon: "simple-icon-star",
        label: "menu.tickets",
        to: "/app/support/tickets",
      },
      {
        icon: "simple-icon-event",
        label: "menu.visits",
        to: "/app/support/visits",
      },
    ],
  },
];
export default data;

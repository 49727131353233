function trim(s, c) {
  // eslint-disable-next-line no-param-reassign
  if (c === "]") c = "\\]";
  // eslint-disable-next-line no-param-reassign
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp(`^[${c}]+|[${c}]+$`, "g"), "");
}

function extractFence(fence) {
  const localFence = fence;
  let dataString = trim(localFence, ")");
  dataString = trim(dataString, "(");

  const dataArr = dataString.split(")(");
  const geoArr = [];
  let foo;
  dataArr.forEach(e => {
    foo = e.split(",");
    geoArr.push({ lat: parseFloat(foo[0]), lng: parseFloat(foo[1]) });
  });

  return geoArr;
}

function isNumber(evt) {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

// const dm = new maps.drawing.DrawingManager({

const mapPolygon = function getPoly(updateCallback, deleteCallback) {
  return {
    id: -1,
    coordinates: [],
    polygon: null,
    visible: false,
    infowindow: null,
    marker: null,
    infoWindowIsOpen: false,
    showEditFom: false,
    updateCallback: updateCallback,
    deleteCallback: deleteCallback,
    init() {
      return this;
    },
    render(id, map, maps, points, itemObject) {
      const self = this;
      this.id = id;
      this.itemObject = itemObject;

      if (!points || !map || !maps) {
        return;
      }

      this.coordinates = extractFence(points);

      this.polygon = new maps.Polygon({
        paths: this.coordinates,
        strokeColor: "#dd61ed",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#dd61ed",
        fillOpacity: 0.35
      });

      this.polygon.setMap(map);
      this.polygon.setVisible(false);
      this.visible = false;

      const thePoint = this.coordinates[0];

      this.marker = new maps.Marker({
        map: map,
        position: thePoint,
        title: itemObject.title || "",
        icon: "/map-assets/location.png"
      });

      this.infowindow = new maps.InfoWindow({
        content: self.getForm(self)
      });

      maps.event.addListener(this.marker, "click", function () {
        self.toggleMapClick(map);
      });

      maps.event.addListener(this.polygon, "click", function () {
        self.toggleMapClick(map);
      });

      return this;
    },
    dismiss(self) {
      self.infoWindowIsOpen = false;
      self.infowindow.close();
    },
    toggleMapClick(map) {
      if (this.infoWindowIsOpen) {
        this.infoWindowIsOpen = false;
        this.infowindow.close();
      } else {
        if (this.poinameInput && this.radiuInput) {
          this.poinameInput.value = this.itemObject.title;
          this.radiuInput.value = this.itemObject.speed_limit;
          this.poinameInput.focus();
        }
        this.infowindow.open(map, this.marker);
        this.infoWindowIsOpen = true;
        this.toggle(map);
      }
    },
    toggle(map) {
      this.visible = !this.visible;
      this.polygon.setVisible(this.visible);

      if (map && this.visible && this.coordinates.length) {
        map.panTo(this.coordinates[0]);
        map.setZoom(15);
      }
    },
    show(visibility) {

      this.visible = visibility;
      this.polygon.setVisible(visibility);

    },
    getContext() {
      return this;
    },
    update: function (self) {
      if (self.poinameInput.value.length < 3) {
        self.labelError.innerText = "Please enter a valid name";
        return;
      }
      if (self.updateCallback) {
        self.updateCallback(
          self.itemObject.autoid,
          self.poinameInput.value,
          self.radiuInput.value,
          self.pathPoints
        );
      }
      self.dismiss(self);
    },
    delete: function (self) {

      if (self.deleteCallback) {
        self.deleteCallback(self.itemObject.autoid);
      }

      self.dismiss(self);
      self.removeFromMap(self);

    },
    removeFromMap(self) {

      if (self.marker) {
        self.marker.setVisible(false);
        self.marker.setMap(null);
      }

      if (self.polygon) {
        self.polygon.setMap(null);
      }
    },
    getForm(self) {
      let htmlObject = document.createElement("div");
      htmlObject.setAttribute("style", "width: 200px");

      let h3 = document.createElement("h4");
      h3.innerText = "Edit Geofence";

      let div2 = document.createElement("div");

      let labelName = document.createElement("label");
      labelName.innerText = "Geofence Name";
      let inputName = document.createElement("input");
      inputName.setAttribute("type", "text");
      inputName.setAttribute("class", "form-control");
      inputName.setAttribute("name", "poi_name");

      let labelRadius = document.createElement("label");
      labelRadius.innerText = "Speed Limit(kmph)";
      let inputRadius = document.createElement("input");
      inputRadius.setAttribute("type", "text");
      inputRadius.setAttribute("class", "form-control");
      inputRadius.setAttribute("name", "radius");
      inputRadius.onkeypress = function (event) {
        return isNumber(event);
      };

      let buttonCancel = document.createElement("button");
      buttonCancel.setAttribute("type", "button");
      buttonCancel.setAttribute("class", "mr-1 btn btn-secondary btn-xs");
      buttonCancel.innerText = "Cancel";

      buttonCancel.addEventListener("click", function () {
        self.dismiss(self);
      });

      let buttonAdd = document.createElement("button");
      buttonAdd.setAttribute("type", "button");
      buttonAdd.setAttribute("class", "mr-1 btn btn-primary btn-xs");
      buttonAdd.innerText = "Update";

      buttonAdd.addEventListener("click", function () {
        self.update(self);
      });

      let buttonDelete = document.createElement("button");
      buttonDelete.setAttribute("type", "button");
      buttonDelete.setAttribute("class", "mr-1 btn btn-danger btn-xs");
      buttonDelete.innerText = "Delete";

      buttonDelete.addEventListener("click", function () {
        self.delete(self);
      });

      let br = document.createElement("br");
      let labelError = document.createElement("label");

      div2.appendChild(labelName);
      div2.appendChild(inputName);
      div2.appendChild(labelRadius);
      div2.appendChild(inputRadius);

      div2.appendChild(br);
      div2.appendChild(buttonAdd);
      div2.appendChild(buttonDelete);
      div2.appendChild(buttonCancel);

      div2.appendChild(labelError);

      // htmlObject.appendChild(h3);
      htmlObject.appendChild(div2);

      this.poinameInput = inputName;
      this.radiuInput = inputRadius;
      this.labelError = labelError;

      inputRadius.value = "0";

      if (this.itemObject && !this.showEditFom) {

        let htmlObjectTitle = document.createElement("div");
        // htmlObjectTitle.setAttribute("style", "width: 200px");

        //htmlObjectTitle.innerText = this.itemObject.title +', ' + this.itemObject.speed_limit ;
        htmlObjectTitle.innerText = this.itemObject.speed_limit ? 
                  this.itemObject.title + ', ' + this.itemObject.speed_limit : this.itemObject.title;

        let buttonEditFom = document.createElement("button");
        buttonEditFom.setAttribute("type", "button");
        buttonEditFom.setAttribute("class", " btn btn-link btn-xs");
        buttonEditFom.innerText = "Edit";

        buttonEditFom.addEventListener("click", function () {
          self.showEditFom = !self.showEditFom;

          if (self.showEditFom) {
            htmlObjectTitle.appendChild(htmlObject);
            buttonEditFom.innerText = "Close";
          } else {
            htmlObjectTitle.removeChild(htmlObject);
            buttonEditFom.innerText = "Edit";
          }
        });

        htmlObjectTitle.appendChild(buttonEditFom);

        return htmlObjectTitle;
      }

      return htmlObject;
    }
  };
};

const mapPolygonManager = {
  polygons: [],
  map: null,
  maps: null,
  updatePolygons(arrayPoly, map, maps, updateCallback, deleteCallback) {
    this.map = map;
    this.maps = maps;

    arrayPoly.forEach(el => {
      const found = this.polygons.filter(x => x.id === el.autoid);
      if (found && found.length) {
        found[0].render(el.autoid, map, maps, el.points, el);
      } else {
        const poly = mapPolygon(updateCallback, deleteCallback);
        poly.render(el.autoid, map, maps, el.points, el);
        this.polygons.push(poly);
      }
    });
  },
  toggle(autoid) {
    const found = this.polygons.filter(x => x.id === autoid);
    if (found && found.length) {
      found[0].toggle(this.map);
    }
  },
  showAll(visibility) {

    this.polygons.forEach(element => {

      element.show(visibility);

    });

  }
};

module.exports = mapPolygonManager;

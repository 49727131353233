import React, { Component } from "react";
import {
  Button,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  ButtonGroup,
} from "reactstrap";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import { LiveSelectByToken } from "../../api-tasks/live";
import TableRow from "../../components-bo/TableRow";
import { NotificationManager } from "../../components/common/react-notifications";
import { AppHelperDateAndTimeToString } from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";
const pagingLimit = -1;
const THREE_MIN = 3 * 60 * 1000;
// const FIFTEEN_MIN = 15 * 60 * 1000;
// const TWO_DAYS = 56 * 60 * 60 * 1000;

const headers = [
  { label: "Plate", key: "plateNumber" },
  { label: "Asset", key: "assetNumber" },
  { label: "Cluster", key: "cluster" },
  { label: "Model", key: "model" },
  { label: "GpsTime", key: "gpsTime" },
  { label: "GeoPoint", key: "geoPoint" },
  { label: "Fuel", key: "fuel" },
  { label: "UpdateTime", key: "updateTime" },
  { label: "Driver name", key: "driverName" },
  { label: "Ignition", key: "ignition" },
  { label: "Status", key: "status" },
  { label: "Device Id", key: "deviceId" },
  { label: "Sim Provider", key: "simProvider" },
  { label: "Sim No", key: "sim" },
  { label: "Sim No2", key: "sim2" },
  { label: "Installation", key: "installation" },
  { label: "Expiry", key: "expiry" },
  { label: "Reminig Days", key: "reminigDays" },
  { label: "Renewal Interval", key: "renewalIn" },
  { label: "Groups", key: "groupNames" },
];

function connectedStatus(updateTime, noDataTime, reminigDays) {
  if (reminigDays < 0) {
    return "Expired";
  }
  if (!updateTime) return "-";
  if (new Date() - new Date(updateTime) < noDataTime) return "Connected";
  return "No data";
}

function liveStatus(ignition, updateTime) {
  if (!updateTime) return "-";
  if (ignition && new Date() - new Date(updateTime) < THREE_MIN) return "On";
  return "Off";
}

class Vehicles extends Component {
  constructor(props) {
    super();
    this.liveMapNoDataInMillisecond = 2880 * 60 * 1000;

    this.state = {
      selectedVehicles: [],
      loading: false,
      allRows: [],
      filteredRows: [],
      totalCount: 0,
      totalVehicles: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - trips.csv",
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    if (this.props.subscriberDetails) {
      const attributes = this.props.subscriberDetails.attributes;
      const noDataMin = parseInt(attributes.liveMap.noDataMin, 10);
      this.liveMapNoDataInMillisecond = noDataMin * 60 * 1000;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.subscriberDetails !== prevProps.subscriberDetails) {
      const attributes = this.props.subscriberDetails.attributes;
      const noDataMin = parseInt(attributes.liveMap.noDataMin, 10);
      this.liveMapNoDataInMillisecond = noDataMin * 60 * 1000;
    }

    // const userDetails = this.props.userDetails || {};
    // const authType = userDetails.userType ;
  }

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
      filteredRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  findLiveStatus = (vehicleId, response) => {
    const foo = response.filter(function (x) {
      return x.vehicle_id === vehicleId;
    });

    return foo[0] || {};
  };

  findSubscriber = (subscriberId, subscriberList) => {
    const foo = subscriberList.filter(function (x) {
      return x.autoid === subscriberId;
    });

    return foo[0] || {};
  };

  groupByCompanyName = (allData) => {
    let groupedData = [];
    let dataNames = [];
    let totalVehicles = 0 ;
    for (let item of allData) {
      let itemData = [];
      let companyName = item.companyName;

      if (dataNames.indexOf(companyName) > -1) {
        continue;
      }

      // itemData.push(item);
      for (let i of allData) {
        if (i.companyName === companyName) {
          itemData.push(i);
        }
      }
      totalVehicles = totalVehicles + itemData.length ;
      groupedData.push({ companyName: companyName, data: itemData });
      dataNames.push(companyName);
    }

    return { groupedVehicles: groupedData, totalVehicles};
  };

  onDateRangeGo = () => {
    if (!this.state.selectedVehicles.length) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const vehicles = this.state.selectedVehicles;

    // console.log('the vehicles', vehicles);

    this.setState({ loading: true });

    LiveSelectByToken(user.userToken).then((response) => {
      this.setState({
        loading: false,
        fileName: `Ctraac - Live status ${moment().format(
          "YYY-MM-DD hh:mm A"
        )}.csv`,
      });
      const groupList = this.props.groups || [];
      if (response && Array.isArray(response)) {

        // console.log('x.item ', vehicles);

        const allRows = vehicles.map((x) => {
          const foo = this.findLiveStatus(x.key, response);

          const gList = [];
          groupList.forEach((gItem) => {
            if (gItem.vehicleData.indexOf(x.key.toString()) !== -1) {
              gList.push(gItem);
              // console.log('a lot of ', gItem);
            }
          });
          
          return {
            webId: x.key,
            plateNumber: x.item.plate_number,
            assetNumber: x.item.fleet_number,
            cluster: x.item.project,
            model: x.item.model,
            companyName: x.item.company_name,
            gpsTime: AppHelperDateAndTimeToString(foo.gpstime),
            geoPoint: foo.lat ? `${foo.lat},${foo.lng}` : "",
            fuel: `${foo.fuel > 0 ? foo.fuel : "-"}`,
            updateTime: AppHelperDateAndTimeToString(foo.updatetime),
            timeStamp: new Date(foo.updatetime),
            driverName: "",
            ignition: liveStatus(foo.ignition, foo.gpstime),
            status: connectedStatus(
              foo.updatetime,
              this.liveMapNoDataInMillisecond,
              x.item.reminigDays
            ),
            manufacture_name: x.item.manufacture_name,
            manufactureIcon: x.item.manufactureIcon,
            typeText: x.typeText,
            typeIcon: x.typeIcon,
            deviceId: x.item.device_id,
            simProvider: x.item.sim_provider,
            sim: x.item.sim,
            sim2: `${x.item.sim} #`,
            installation: moment(x.item.created_date).format("DD MMM yyyy"),
            expiry: moment(x.item.expiry_date).format("DD MMM yyyy"),
            reminigDays: x.item.reminigDays,
            renewalIn: x.item.re_newal_interval,
            groupList: gList,
            groupNames: gList.map((g) => g.group_name).join(", "),
            vehicleState : x.item.status
          };
        });

        // const sortedRows = allRows.sort((a, b) => b.companyName - a.companyName);
        const sortedRows = allRows;

        const gData = this.groupByCompanyName(sortedRows);
        this.setState({
          allRows: sortedRows,
          filteredRows: gData.groupedVehicles,
          totalCount: response.length,
          totalVehicles: gData.totalVehicles,
          touched: true,
        });
      } else {
        this.props.authApiAccessCheck(this.props.history, response);
      }
    });
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                      allowExpired={true}
                      autoSelectAll={true}
                    />
                  </Colxx>
                </Row>
                <Row>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {this.state.selectedVehicles.length && this.state.touched ? (
                  <CardTitle>Vehicles</CardTitle>
                ) : (
                  <CardTitle>
                    {intl.formatMessage({
                      id: "report.try-search",
                    })}
                  </CardTitle>
                )}

                {this.state.touched && !this.state.totalCount ? (
                  <p>
                    {intl.formatMessage({
                      id: "report.nothing-show",
                    })}
                  </p>
                ) : (
                  ""
                )}
                <div className="card-body-min-height">
                  <div className="float-right">
                    <ButtonGroup className="mr-1">
                      <Button
                        outline
                        size="xs"
                        color="primary"
                        onClick={() => {
                          const gData = this.groupByCompanyName( this.state.allRows);
                          this.setState({
                            filteredRows: gData.groupedVehicles,
                            totalVehicles: gData.totalVehicles,
                          });
                        }}
                      >
                        All
                      </Button>
                      <Button
                        outline
                        size="xs"
                        color="primary"
                        onClick={() => {
                          const gData = this.groupByCompanyName(
                            this.state.allRows.filter(
                              (el) =>
                                el.reminigDays < 30 && el.reminigDays > 0
                            )
                          );

                          this.setState({
                            filteredRows: gData.groupedVehicles,
                            totalVehicles: gData.totalVehicles,
                          });
                        }}
                      >
                        Soon Expiry
                      </Button>


                      <Button
                        outline
                        size="xs"
                        color="primary"
                        onClick={() => {
                          const gData = this.groupByCompanyName(
                            this.state.allRows.filter(
                              (el) => el.reminigDays < 0
                            )
                          );

                          this.setState({
                            filteredRows: gData.groupedVehicles,
                            totalVehicles: gData.totalVehicles,
                          });
                        }}
                      >
                        Expired
                      </Button>

                      <Button
                        outline
                        size="xs"
                        color="primary"
                        onClick={() => {
                          const gData = this.groupByCompanyName(
                            this.state.allRows.filter(
                              (el) =>
                                el.reminigDays < 0 && el.reminigDays > -45
                            )
                          );

                          this.setState({
                            filteredRows: gData.groupedVehicles,
                            totalVehicles: gData.totalVehicles,
                          });
                        }}
                      >
                        Recent Expiry
                      </Button>

                      <Button
                        outline
                        size="xs"
                        color="primary"
                        onClick={() => {
                          const gData = this.groupByCompanyName(
                            this.state.allRows.filter(
                              (el) => el.status === "No data"
                            )
                          );

                          this.setState({
                            filteredRows: gData.groupedVehicles,
                            totalVehicles: gData.totalVehicles,
                          });
                        }}
                      >
                        No data
                      </Button>
                      <Button
                        outline
                        size="xs"
                        color="primary"
                        onClick={() => {
                          const gData = this.groupByCompanyName(
                            this.state.allRows.filter(
                              (el) => el.status === "Connected"
                            )
                          );

                          this.setState({
                            filteredRows: gData.groupedVehicles,
                            totalVehicles: gData.totalVehicles,
                          });
                        }}
                      >
                        Connected
                      </Button>
                    </ButtonGroup>

                    <CSVLink
                      headers={headers}
                      filename={this.state.fileName}
                      data={this.state.filteredRows}
                      enclosingCharacter={`'`}
                    >
                      <Button
                        title={this.state.fileName}
                        size="xs"
                        outline
                        color="dark"
                      >
                        <IntlMessages id="common.download" />
                      </Button>
                    </CSVLink>
                  </div>
                   

                    {`Vehicle ${this.state.totalVehicles}, Account ${this.state.filteredRows.length}`}

                  <Row className={"mt-2"}>
                    <Colxx xxs="12"></Colxx>
                  </Row>

                  <Row>
                    <Table responsive striped>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Company</th>
                          <th>Vehicles</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.filteredRows.map((item, i) => (
                          <TableRow theRow={item} key={i} />
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  vehicles,
  authUser,
  subscriber,
  settings,
  groups,
}) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { details, allList } = subscriber;
  const { locale } = settings;
  const userDetails = authUser.details;
  return {
    vehiclesAll: all,
    user,
    userDetails,
    subscriberDetails: details,
    subscriberAllList: allList,
    locale,
    groups: groups.items,
  };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Vehicles);

import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Vehicles from "../../../containers/pages/vehicles";

class PagesVehicles extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.vehicles" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Vehicles />
      </Fragment>
    );
  }
}

export default PagesVehicles;

import React, { Fragment, Component } from "react";

import {
  Button,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Nav,
  NavItem,
  Table,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { NavLink } from "react-router-dom";

import classnames from "classnames";

import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import { GetIdlingByVehicles } from "../../api-reports2/idle";
import DateRangePicker from "../../components/DateRangePicker";
import TableData from "./idle/TableData";
import Analytics from "./idle/Analytics";
import IntlMessages from "../../helpers/IntlMessages";
import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperSecondsAsHHmm,
} from "../../helpers/AppHelper";
import moment from "moment";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

const pagingLimit = -1;

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];

    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

    return objectsByKeyValue;
  }, {});
const gVehicle = groupBy("vehicle_id");
const gDriver = groupBy("driver_id");

const csvVehiclesGroupBy = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Driver name", key: "driverName" },
  { label: "HH:mm", key: "totalDuration" },
  { label: "Times", key: "times" },
  { label: "AvgDuration", key: "avgDuration" },
];

const csvDriverGroupBy = [
  { label: "Driver name", key: "driverName" },
  { label: "Vehicle", key: "vehicleName" },
  { label: "HH:mm", key: "totalDuration" },
  { label: "Times", key: "times" },
  { label: "AvgDuration", key: "avgDuration" },
];

class Idle extends Component {
  constructor(props) {
    super(props);

    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.state = {
      selectedVehicles: [],
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - idle.csv",
      isOpenSizingXs: false,
      selectedText: "Sort by",
      activeSecondTab: "4",
      showAllRows: false,
      top10Vehicles: [],
      allRowsGroupByVehicle: [],
      allRowsGBDriverInDetail: [],
      selectSecondLimit: 1800,
      allRowsByVehicleJson: [],
      allRowsByDriverJson: [],
    };
  }

  componentWillUnmount() { }

  componentDidMount() { }

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRowsGroupByVehicle: [],
      allRowsGroupByDriver: [],
      allRows: [],
      allRowsByVehicleJson: [],
      allRowsByDriverJson: [],
      totalCount: 0,
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model}`;
        }
      }
    }
    return "";
  };

  onDateRangeGo = (limit, skip) => {
    if (!this.state.selectedVehicles.length) {
      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const vehicleIds = this.state.selectedVehicles.map((el) => {
      return el.key;
    });

    const secondsOrAbove = this.state.selectSecondLimit || 0;

    this.setState({ loading: true });

    GetIdlingByVehicles(
      user.userToken,
      vehicleIds,
      startTime,
      endTime,
      secondsOrAbove,
      limit,
      skip
    ).then((response) => {
      this.setState({
        loading: false,
        limit,
        skip,
        fileName: `Ctraac - Idle ${this.state.startTime.format(
          "YYY-MM-DD hh:mm A"
        )} to ${this.state.endTime.format("YYY-MM-DD hh:mm A")}.csv`,
      });

      console.log('the punjaab ', response);

      if (response && Array.isArray(response)) {
        const all = response.map((x) => {
          return {
            ...x,
            duration: AppHelperSecondsAsHHmm(x.duration_sec),
            time: AppHelperDateAndTimeToString(x.event_time),
            driverName: this.findDriverName(x),
            vehicleName: this.findVehicleName(x),
            timeStamp: new Date(x.event_time).getTime(),
          };
        });

        const gvAll = gVehicle(all);

        const gvAllArray = Object.keys(gvAll).map(function (key) {
          const data = gvAll[key] || [];
          const vehicleName = data[0].vehicleName || "";
          const totalSec = data.reduce((a, b) => a + b.duration_sec, 0);
          const avgSec = totalSec / data.length;

          const gdData = gDriver(data);

          const gdAllArray = Object.keys(gdData).map((itemKey) => {
            const itemData = gdData[itemKey] || [];
            const driverName = itemData[0].driverName || "---";
            const itemTotalSec = itemData.reduce(
              (a, b) => a + b.duration_sec,
              0
            );

            const itemAvgSec = itemTotalSec / itemData.length;

            return {
              driverName,
              itemData,
              itemTotalSec,
              itemAvgSec,
              itemTotalDuration: AppHelperSecondsAsHHmm(itemTotalSec),
              itemAvgDuration: AppHelperSecondsAsHHmm(itemAvgSec),
            };
          });

          const gdAllArraySorted = gdAllArray.sort(
            (a, b) => b.itemAvgSec - a.itemAvgSec
          );

          return {
            vehicleName,
            totalSec,
            avgSec: avgSec,
            totalDuration: AppHelperSecondsAsHHmm(totalSec),
            avgDuration: AppHelperSecondsAsHHmm(avgSec),
            data,
            dataDriver: gdAllArraySorted,
            times: data.length,
          };
        });

        const gvAllArraySorted = gvAllArray.sort((a, b) => b.avgSec - a.avgSec);

        const allRowsGroupByVehicle = [];

        gvAllArraySorted.forEach((el, index) => {
          allRowsGroupByVehicle.push({
            row: index + 1,
            vehicleName: el.vehicleName,
            driverName: "",
            totalDuration: el.totalDuration,
            times: el.data.length,
            avgDuration: el.avgDuration,
          });

          el.dataDriver.forEach((elDriver) => {
            allRowsGroupByVehicle.push({
              row: "",
              vehicleName: "",
              driverName: elDriver.driverName,
              totalDuration: elDriver.itemTotalDuration,
              times: elDriver.itemData.length,
              avgDuration: elDriver.itemAvgDuration,
            });
          });
        });

        // group by drivers start here --
        const allGBDriverId = gDriver(all);

        const allRowsGBDriverInDetail = Object.keys(allGBDriverId).map(
          (key) => {
            const data = allGBDriverId[key] || [];
            const driverName = data[0].driverName || "---";
            const totalSec = data.reduce((a, b) => a + b.duration_sec, 0);
            const avgSec = totalSec / data.length;

            const gvData = gVehicle(data);

            const gvAllArray = Object.keys(gvData).map((itemKey) => {
              const itemData = gvData[itemKey] || [];
              const vehicleName = itemData[0].vehicleName || "---";
              const itemTotalSec = itemData.reduce(
                (a, b) => a + b.duration_sec,
                0
              );

              const itemAvgSec = itemTotalSec / itemData.length;

              return {
                vehicleName,
                itemData,
                itemTotalSec,
                itemAvgSec,
                itemTotalDuration: AppHelperSecondsAsHHmm(itemTotalSec),
                itemAvgDuration: AppHelperSecondsAsHHmm(itemAvgSec),
              };
            });

            const gvAllArraySorted = gvAllArray.sort(
              (a, b) => b.itemAvgSec - a.itemAvgSec
            );

            return {
              driverName,
              data,
              totalSec,
              avgSec,
              totalDuration: AppHelperSecondsAsHHmm(totalSec),
              avgDuration: AppHelperSecondsAsHHmm(avgSec),
              dataVehicle: gvAllArraySorted,
              times: gvAllArraySorted.length,
            };
          }
        );

        const gdAllArraySorted = allRowsGBDriverInDetail.sort(
          (a, b) => b.avgSec - a.avgSec
        );

        const allRowsGroupByDriver = [];

        gdAllArraySorted.forEach((el, index) => {
          allRowsGroupByDriver.push({
            row: index + 1,
            driverName: el.driverName,
            vehicleName: "",
            totalDuration: el.totalDuration,
            times: el.data.length,
            avgDuration: el.avgDuration,
          });

          el.dataVehicle.forEach((el) => {
            allRowsGroupByDriver.push({
              row: "",
              driverName: "",
              vehicleName: el.vehicleName,
              totalDuration: el.itemTotalDuration,
              times: el.itemData.length,
              avgDuration: el.itemAvgDuration,
            });
          });
        });

        // group by drivers end here --

        this.setState({
          allRowsGroupByVehicle,
          allRowsGroupByDriver,
          allRows: all,
          allRowsByVehicleJson: gvAllArraySorted,
          allRowsByDriverJson: gdAllArraySorted,
          totalCount: response.length,
          touched: true,
        });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
    });
    if (this.state.selectedVehicles.length) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  toggleSizingXs = () => {
    this.setState((prevState) => ({
      isOpenSizingXs: !prevState.isOpenSizingXs,
    }));
  };

  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab,
      });
    }
  }

  renderTable_allRowsGroupByVehicle() {
    const showRows = this.state.allRowsGroupByVehicle || [];

    return (
      <React.Fragment>
        <div className="float-right">
          <CSVLink
            headers={csvVehiclesGroupBy}
            filename={this.state.fileName}
            data={showRows}
          >
            <Button title={this.state.fileName} size="xs" outline color="dark">
              <IntlMessages id="common.download" />
            </Button>
          </CSVLink>
        </div>

        <Table responsive hover bordered striped>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <IntlMessages id="report.vehicle" />
              </th>
              <th>
                <IntlMessages id="report.driver" />
              </th>
              <th>HH:mm</th>
              <th>
                <IntlMessages id="report.times" />
              </th>
              <th>
                <IntlMessages id="report.avg" /> HH:mm
              </th>
            </tr>
          </thead>
          <tbody>
            {showRows.map((item, i) => (
              <Fragment key={i}>
                <tr>
                  <th scope="row">{item.row}</th>
                  <td>{item.vehicleName}</td>
                  <td> {item.driverName} </td>
                  <td> {item.totalDuration}</td>

                  <td>{item.times}</td>

                  <td> {item.avgDuration}</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }

  renderTable_allRowsGroupByDriver() {
    const showRows = this.state.allRowsGroupByDriver || [];

    return (
      <React.Fragment>
        <div className="float-right">
          <CSVLink
            headers={csvDriverGroupBy}
            filename={this.state.fileName}
            data={showRows}
          >
            <Button title={this.state.fileName} size="xs" outline color="dark">
              <IntlMessages id="common.download" />
            </Button>
          </CSVLink>
        </div>

        <Table responsive hover bordered striped>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <IntlMessages id="report.driver" />
              </th>
              <th>
                <IntlMessages id="report.vehicle" />
              </th>
              <th>HH:mm</th>
              <th>
                <IntlMessages id="report.times" />
              </th>
              <th>
                <IntlMessages id="report.avg" /> HH:mm
              </th>
            </tr>
          </thead>
          <tbody>
            {showRows.map((item, i) => (
              <Fragment key={i}>
                <tr>
                  <th scope="row">{item.row}</th>
                  <td> {item.driverName} </td>
                  <td>{item.vehicleName}</td>
                  <td> {item.totalDuration}</td>

                  <td>{item.times}</td>

                  <td> {item.avgDuration}</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <VehicleSelectWithBatch
                      onChange={this.handleOnSelectedVehicleChange}
                    />
                  </Colxx>
                </Row>
                <Row>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      suggestions="months"
                      startTime={moment().startOf("month")}
                      dateOnly={true}
                    />
                  </Colxx>
                  <Colxx xl="2" lg="2" md="6" sm="12">
                    <select
                      className="form-control"
                      onChange={(event) => {
                        this.setState({
                          selectSecondLimit: event.target.value,
                        });

                        setTimeout(() => {
                          this.onDateRangeGo(this.state.limit, this.state.skip);
                        }, 5);
                      }}
                      value={this.state.selectSecondLimit}
                    >
                      <option value="300">5min or above </option>
                      <option value="600">10min or above </option>
                      <option value="900">15min or above </option>
                      <option value="1800">30min or above </option>
                      <option value="2700">45min or above </option>
                      <option value="3600">1hrs or above </option>
                    </select>
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row className="p-2">
          <Colxx xxs="12" className="mb-2 mt-2">
            <p className="float-right">
              {this.state.allRows.length + " " + intl.formatMessage({
                id: "report.records",
              })}
            </p>
            {this.state.selectedVehicles.length && this.state.touched ? (
              <CardTitle>
                {intl.formatMessage({
                  id: "report.idling",
                })}
                {" from "}
                {AppHelperDateAndTimeToString(this.state.startTime.toDate())}
                {" to "}
                {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
              </CardTitle>
            ) : (
              <CardTitle>
                {intl.formatMessage({
                  id: "report.try-search",
                })}
              </CardTitle>
            )}
            {!this.state.totalCount ? (
              <p>
                {intl.formatMessage({
                  id: "report.nothing-to-show",
                })}
                {!this.state.selectedVehicles.length
                  ? intl.formatMessage({
                    id: "report.zero-vehicle",
                  })
                  : ""}
              </p>
            ) : (
              ""
            )}
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              {!this.state.totalCount ? (
                ""
              ) : (
                <div className="card-body-min-height">
                  <CardHeader className="pl-0 pr-0">
                    <Nav tabs className=" card-header-tabs  ml-0 mr-0">
                      <NavItem className="text-center">
                        <NavLink
                          className={classnames({
                            active: this.state.activeSecondTab === "1",
                            "nav-link": true,
                          })}
                          onClick={() => {
                            this.toggleSecondTab("1");
                          }}
                          to="#"
                        >
                          <h6>

                            {intl.formatMessage({
                              id: "report.table",
                            })}
                          </h6>
                        </NavLink>
                      </NavItem>
                        
                      <NavItem className="text-center">
                        <NavLink
                          className={classnames({
                            active: this.state.activeSecondTab === "4",
                            "nav-link": true,
                          })}
                          onClick={() => {
                            this.toggleSecondTab("4");
                          }}
                          to="#"
                        >
                          <h6>

                            <IntlMessages id="report.analytics" />
                          </h6>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>

                  <TabContent activeTab={this.state.activeSecondTab}>
                    <TabPane tabId="1">
                      <Row className="p-2">
                        <Colxx xxs="12">
                          <TableData
                            allRows={this.state.allRows}
                            fileName={this.state.fileName}
                          />
                        </Colxx>
                      </Row>
                    </TabPane>
                   
                     
                    <TabPane tabId="4">
                      <Row className="p-4">
                        <Analytics
                          allRows={this.state.allRows}
                          byVehicles={this.state.allRowsByVehicleJson}
                          byDrivers={this.state.allRowsByDriverJson}
                        />
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              )}
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Idle);

import React, { Fragment } from "react";
import { Button, Row, Badge } from "reactstrap";
import { Colxx } from "../components/common/CustomBootstrap";
import LocationName from "../components/LocationName";
import CsvDownloader from "../components/CsvDownloader";

const headers = [
  { label: "Plate", key: "plateNumber" },
  { label: "Asset", key: "assetNumber" },
  { label: "Cluster", key: "cluster" },
  { label: "Model", key: "model" },
  { label: "GpsTime", key: "gpsTime" },
  { label: "GeoPoint", key: "geoPoint" },
  { label: "Fuel", key: "fuel" },
  { label: "UpdateTime", key: "updateTime" },
  { label: "Driver name", key: "driverName" },
  { label: "Ignition", key: "ignition" },
  { label: "Status", key: "status" },
  { label: "Device Id", key: "deviceId" },
  { label: "Sim Provider", key: "simProvider" },
  { label: "Sim No", key: "sim" },
  { label: "Sim No2", key: "sim2" },
  { label: "Installation", key: "installation" },
  { label: "Expiry", key: "expiry" },
  { label: "Reminig Days", key: "reminigDays" },
  { label: "Renewal Interval", key: "renewalIn" },
  { label: "Groups", key: "groupNames" },
];

class TableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theRow: {},
      showInfo: false,
      idleShowAll: false,
      overSpeedShowAll: false,
      theTimeLine: [],
    };
  }

  getTimeLine = (theRow) => {
    // type 1 : idle, time, item
    let aTimeline = [];
    let idleTimeLine = [];
    if (theRow.idles && theRow.idles.length) {
      idleTimeLine = theRow.idles.map((x) => {
        return {
          vehicleName: theRow.vehicleName,
          summaryDayText: theRow.summaryDayText,
          type: 3,
          text: "Idle",
          time: new Date(x.startTime),
          place: x.place,
          geo: x.geo,
          timeText: x.startTimeText,
          details: " duration:" + x.duration,
          item: x,
        };
      });
    }

    // mobi trac , 250mb , 50
    let stopeTimeLine = [];
    if (theRow.stops && theRow.stops.length) {
      stopeTimeLine = theRow.stops.map((x) => {
        return {
          vehicleName: theRow.vehicleName,
          summaryDayText: theRow.summaryDayText,
          type: 4,
          text: "Stop",
          time: new Date(x.startTime),
          place: x.place,
          geo: x.geo,
          timeText: x.startTimeText,
          item: x,
        };
      });
    }

    stopeTimeLine.splice(0, 1);

    const errands = theRow.errands;

    let errandsStartTimeLine = [];
    let errandsEndTimeLine = [];

    if (errands && errands.length) {
      errandsStartTimeLine = errands.map((x) => {
        return {
          vehicleName: theRow.vehicleName,
          summaryDayText: theRow.summaryDayText,
          type: 1,
          text: "Start",
          time: new Date(x.startTime),
          place: x.startPlace,
          geo: x.startGeo,
          timeText: x.startTimeText,
          item: x,
        };
      });

      aTimeline = aTimeline.concat(errandsStartTimeLine);

      errandsEndTimeLine = errands.map((x) => {
        return {
          vehicleName: theRow.vehicleName,
          summaryDayText: theRow.summaryDayText,
          type: 2,
          text: "End",
          time: new Date(x.endTime),
          place: x.endPlace,
          geo: x.endGeo,
          timeText: x.endTimeText,
          details: " duration:" + x.duration + ", distance:" + x.distance,
          item: x,
        };
      });

      aTimeline = aTimeline.concat(errandsEndTimeLine);
    }

    aTimeline = aTimeline.concat(stopeTimeLine);
    aTimeline = aTimeline.concat(idleTimeLine);

    const theTimeLine = aTimeline.sort((a, b) => a.time - b.time);
    // const theTimeLineWithStart = [start, ...theTimeLine, end];

    this.setState({ theTimeLine: theTimeLine });
    // console.log('the timeline ', theTimeLine);
  };

  componentDidMount() {
    this.setState({ theRow: this.props.theRow || {} });
    this.getTimeLine(this.props.theRow);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.theRow !== this.props.theRow) {
      this.setState({ theRow: this.props.theRow || {} });
      this.getTimeLine(this.props.theRow);
    }
  }

  handleLocationNameOnClick = () => {
    alert("the call");
  };

  handleShowToggleOnClick = () => {
    this.setState({ showInfo: !this.state.showInfo });
  };

  handleIdleShowAllOnClick = () => {
    this.setState({ idleShowAll: !this.state.idleShowAll });
  };

  handleOverSpeedShowAllOnClick = () => {
    this.setState({ overSpeedShowAll: !this.state.overSpeedShowAll });
  };

  renderBadge(type, text) {
    switch (type) {
      case 3:
        return (
          <Badge color="danger" pill className="mb-1 mr-1">
            {text}
          </Badge>
        );

      case 4:
        return (
          <Badge color="warning" pill className="mb-1 mr-1">
            {text}
          </Badge>
        );

      default:
        return (
          <Badge color="info" pill className="mb-1 mr-1">
            {text}
          </Badge>
        );
    }
  }

  render() {
    const theRow = this.props.theRow || {};
    if (!theRow.companyName) {
      return <Fragment />;
    }

    return (
      <Fragment>
        <tr>
          <td>
            <Button
              onClick={this.handleShowToggleOnClick}
              outline
              color="info"
              size="xs"
            >
              {this.state.showInfo ? "-" : "+"}
            </Button>
          </td>
          <td> {theRow.companyName} </td>
          <td> {theRow.data.length} </td>
        </tr>

        {this.state.showInfo ? (
          <tr>
            <td colSpan="10">
              <Row className="w-100">
                <Colxx xl="12" lg="12" md="12" sm="12" className="timeline56">

                  <Row>
                    <Colxx xl="12" lg="12" md="12" sm="12" >
                      <span className="float-right">
                        <CsvDownloader
                          headers={headers}
                          filename={'ctraac-vehicles.csv'}
                          data={theRow.data}
                        />
                      </span>
                    </Colxx>
                  </Row>



                  {theRow.data.map((item, i) => (
                    <Row
                      key={i}
                      className={`border-bottom p-2 ${i % 2 ? `bg-light` : ``}`}
                    >



                      <Colxx xl="3" lg="3" md="12" sm="12">
                        {i + 1}

                        <img
                          title={item.manufacture_name}
                          className="app-select-icon"
                          src={item.manufactureIcon}
                          alt="icon"
                        />
                        <img
                          title={item.typeText}
                          className="app-select-icon"
                          src={item.typeIcon}
                          alt="icon"
                        />

                        {`${item.plateNumber} ${item.assetNumber} ${item.model} ${item.cluster}`}
                      </Colxx>

                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <Badge
                          color={
                            item.status === "Connected"
                              ? `primary`
                              : item.status === "Expired"
                                ? `warning`
                                : `info`
                          }
                          className="mb-1 mr-1"
                        >
                          {item.status}
                        </Badge>

                        {item.reminigDays < 0 ? (
                          <small>
                            Expired since {Math.abs(item.reminigDays)} days
                          </small>
                        ) : null}
                      </Colxx>

                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <u>
                          <b>GPS:</b>
                          {item.gpsTime}
                          {" | "}
                          <LocationName
                            place={item.place}
                            geo={item.geoPoint}
                            objectItem={item}
                            showGoogle
                          />
                        </u>
                      </Colxx>

                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <u>
                          <b>Installation:</b>
                          {item.installation}
                        </u>
                      </Colxx>
                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <u>
                          <b>Device:</b>
                          {item.deviceId}
                        </u>
                      </Colxx>

                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <u>
                          <b>Sim:</b>
                          {item.sim}
                          {" | "}
                          {item.simProvider}
                        </u>
                      </Colxx>

                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <u>
                          <b>Last update: </b>
                          {item.updateTime}
                        </u>
                      </Colxx>
                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <u>
                          <b>Expiry:</b>
                          {`${item.expiry} | ${item.renewalIn} | ${item.reminigDays}`}
                        </u>
                      </Colxx>
                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <u>
                          <b>Groups:</b>

                          {item.groupNames}
                        </u>
                      </Colxx>

                      <Colxx xl="3" lg="3" md="12" sm="12">
                        <u>
                          <b>Web Id:</b>

                          {item.webId} : {item.vehicleState}
                        </u>
                      </Colxx>

                      <Colxx xl="4" lg="4" md="12" sm="12">
                        <u>
                          <b>Subscriber:</b>

                          {item.companyName}
                        </u>
                      </Colxx>
                    </Row>
                  ))}
                </Colxx>
              </Row>
            </td>
          </tr>
        ) : null}
      </Fragment>
    );
  }
}

export default TableRow;

import {
    SUBSCRIBER_SELECT_REQUEST,
    SUBSCRIBER_SELECT_SUCCESS,
    SUBSCRIBER_SELECT_ALL_REQUEST,
    SUBSCRIBER_SELECT_ALL_SUCCESS } from '../actions';

  export const subscriberSelectDetailsRequest = ( history ) => ({
    type: SUBSCRIBER_SELECT_REQUEST,
    payload: { history }
  });

  export const subscriberSelectSuccess = (subscriber ) => ({
    type: SUBSCRIBER_SELECT_SUCCESS,
    payload: { subscriber }
  });

  export const subscriberSelectAllRequest = ( history ) => ({
    type: SUBSCRIBER_SELECT_ALL_REQUEST,
    payload: { history }
  });

  export const subscriberSelectAllSuccess = (subscriber ) => ({
    type: SUBSCRIBER_SELECT_ALL_SUCCESS,
    payload: { subscriber }
  });

const megaMapMarker = require("./mega-map-marker");

const MegaMapObj = {
  map: null,
  maps: null,
  vehicles: [],
  lastUpdate: [],
  markerCluster: null,
  handleMapMarkerClick: null,
  theInfoWindow: null,
  mapIcons: {
    Stopped: "/map-assets/gray-dot.png",
    Running: "/map-assets/pickup_running.png",
    Idle: "/map-assets/red-dot.png",
    NoData: "/map-assets/nodata-dot.png"
  },
  init: function(handleMapMarkerClick) {
    this.handleMapMarkerClick = handleMapMarkerClick;
    return this;
  },
  distroy: function() {
    this.vehicles = [];
    this.lastUpdate = [];
  },
  setSelectedMultipleVehicle(vehicles) {
    if (vehicles.length > 0) {
      this.lastUpdate.forEach(elUpdate => {
        // element.show(true, false);
        const found = vehicles.filter(x => x.key === elUpdate.id);
        if (found && found.length) {
          if (vehicles.length === 1) {
            elUpdate.show(true, true);
            this.map.panTo(elUpdate.position);
          } else {
            elUpdate.show(true, false);
          }
        } else {
          elUpdate.show(false, false);
        }
      });
    } else {
      this.lastUpdate.forEach(element => {
        element.show(true, false);
      });
    }
  },
  closeAllMapInfowindow() {
    this.lastUpdate.forEach(element => {
      element.show(true, false);
    });
  },
  showInfowindow(vehicleId){
    const a = this.lastUpdate.filter(x => x.id === vehicleId)[0] || {};
    if(a.mapMarker && this.theInfoWindow){
      /*
      let _masterDiv = document.createElement("div");

      let dataInfo = "";
      dataInfo = `${vehicle.plate_number} ${vehicle.fleet_number}`;

      _masterDiv.innerText = dataInfo;

      return _masterDiv;
      */
      this.theInfoWindow.setContent( ' new content of '+ vehicleId );
      this.theInfoWindow.open(this.map, a.mapMarker);
    }

  },
  showVehicle(vehicleId, mapAction) {
    if (vehicleId > 0) {
      this.lastUpdate.forEach(element => {
        if (element.id === vehicleId) {
          // element.showInfowindow();
          if(!mapAction){
            this.map.setCenter(element.position);
            this.map.setZoom(18);
          }
        } else {
          // element.show(false, false);
        }
      });
    }
  },
  setSelectedVehicle(vehicleId) {
    if (vehicleId > 0) {
      this.lastUpdate.forEach(element => {
        if (element.id === vehicleId) {
          element.show(true, true);
          this.map.panTo(element.position);
        } else {
          element.show(false, false);
        }
      });
    } else if (vehicleId === 0) {
      this.lastUpdate.forEach(element => {
        element.show(true, false);
      });
    } else if (vehicleId === -1) {
      this.lastUpdate.forEach(element => {
        element.show(false, false);
      });
    }
  },
  setMap: function(map, maps) {
    let self = this;
    self.map = map;
    self.maps = maps;

    this.theInfoWindow = new this.maps.InfoWindow({
      content: "..."
    });
    //self.markerCluster = new MarkerClusterer(self.map, [],
    //    {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

    if (this.lastUpdate.length) {
      this.setLastUpdates(this.lastUpdate);
    }

    return self;
  },
  setVehicles: function(items) {
    this.vehicles = items;
  },
  setLastUpdates: function(items, noDataTime) {
    if (this.vehicles.length === 0) return;
    if (this.maps === null) return;
    let oldInfo = null;

    items.forEach(newInfo => {
      // checking is same id available
      oldInfo = this.lastUpdate.find(obj => obj.id === newInfo.vehicle_id);

      this._setMegaMarker(oldInfo, newInfo, noDataTime);
    });
  },
  _setMegaMarker: function(oldInfo, newInfo, noDataTime) {
    if (oldInfo) {
      oldInfo.updateInfo(newInfo, noDataTime);
    } else {
      let _vehicle = this.vehicles.find(
        vehi => vehi.autoid === newInfo.vehicle_id
      );
      if (_vehicle) {
        let _marker = this._getNewMegaMarkerObj(newInfo, _vehicle);
        this.lastUpdate.push(_marker);
      }
    }
  },
  _getNewMegaMarkerObj: function(info, vehicle) {
    let self = this;

    let position = { lat: parseFloat(info.lat), lng: parseFloat(info.lng) };

    let mapMarker = new this.maps.Marker({
      id: info.vehicle_id,
      position: position,
      title: `${vehicle.plate_number} ${vehicle.fleet_number}`,
      map: this.map
    });

    self.maps.event.addListener(mapMarker, "click", function(e) {
      if (self.handleMapMarkerClick) {
        self.handleMapMarkerClick(mapMarker.id);
      }
    });

    let _newMarker = megaMapMarker(self, info, vehicle, mapMarker, position);

    return _newMarker;
  }
};

module.exports = MegaMapObj;

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "vehicles";

export const VehicleSelectByToken = userToken => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-by-token", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleUpdate = objItem => {
  return axios
    .post(proxyServer + "/update", { criterion: objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleControlSelectByVehicle = objItem => {
  return axios
    .post(proxyServer + "/get-controls-by-vehicle", { criterion: objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleControlUpdateControl = objItem => {
  return axios
    .post(proxyServer + "/update-control", { criterion: objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleControlUpdateMultipleControl = (userToken, items) => {
  const criterion = { userToken, items };
  return axios
    .post(proxyServer + "/update-control-multiple", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "backoffice/subscriber";

export const SubscriberGetAll = async (userToken) => {
  const criterion = { userToken };
  return new Promise((resolve, reject) => {
    axios
      .post(proxyServer + "/get-all", { criterion })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import vehicleMasterSettings from './vehicle-master';
import profileMasterSettings from './profile-master';
import userMasterSettings from './user-master';
import driverSettings from './driver-master';
import driverView from './driver-view';
import passengerMaster from './passenger-master';
import passengerView from './passenger-view';
import groupMaster from './groups';
import geofencesMaster from './geofences';
import favoritePlacesMaster from './favorite-places';

const Pages = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/vehicle-master`} />
            <Route path={`${match.url}/vehicle-master`} component={vehicleMasterSettings} />
            <Route path={`${match.url}/profile-master`} component={profileMasterSettings} />
            <Route path={`${match.url}/user-master`} component={userMasterSettings} />
            
            <Route path={`${match.url}/driver-settings/driver-view`} component={driverView} />
            <Route path={`${match.url}/driver-settings`} component={driverSettings} />
            <Route path={`${match.url}/passenger/passenger-view`} component={passengerView} />
            <Route path={`${match.url}/passenger`} component={passengerMaster} />   
            <Route path={`${match.url}/groups`} component={groupMaster} />   
            <Route path={`${match.url}/geofences`} component={geofencesMaster} />   
            <Route path={`${match.url}/favorite-places`} component={favoritePlacesMaster} />   

            <Redirect to="/error" />

        </Switch>
    </div>
);

export default Pages;

import {
    VEHICLE_SELECT_ALL_REQUEST,
    VEHICLE_SELECT_ALL_SUCCESS,
    VEHICLE_UPDATE_DETAILS,
    VEHICLE_STATE_DISPOSAL,
    BACKOFFICE_VEHICLE_SELECT_ALL_REQUEST,
    BACKOFFICE_VEHICLE_SELECT_ALL_SUCCESS,
  } from '../actions';

  export const vehicleSelectAllRequest = ( forceLoad, history) => ({
    type: VEHICLE_SELECT_ALL_REQUEST,
    payload: { forceLoad, history }
  });

  export const vehicleSelectAllSuccess = (vehicles, clusters ) => ({
    type: VEHICLE_SELECT_ALL_SUCCESS,
    payload: { vehicles, clusters }
  });

  export const backofficeVehicleSelectAllRequest = ( forceLoad, history) => ({
    type: BACKOFFICE_VEHICLE_SELECT_ALL_REQUEST,
    payload: { forceLoad, history }
  });

  export const backofficeVehicleSelectAllSuccess = (vehicles, clusters ) => ({
    type: BACKOFFICE_VEHICLE_SELECT_ALL_SUCCESS,
    payload: { vehicles, clusters }
  });

  export const vehicleUpdateDetails = (history, newDetails, vehicle, callback ) => ({
    type: VEHICLE_UPDATE_DETAILS,
    payload: { history, newDetails, vehicle, callback }
  });

  export const vehicleStateDisposal = (history ) => ({
    type: VEHICLE_STATE_DISPOSAL,
    payload: { history }
  });

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import tcpTerminal from './tcp-terminal';

const Tools = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/tcp-terminal`} />
             
            <Route path={`${match.url}/tcp-terminal`} component={tcpTerminal} />

            <Redirect to="/error" />

        </Switch>
    </div>
);

export default Tools;
import { PAGES_TRIPS_UPDATE } from "../actions";

const INIT_STATE = {
    trips : {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PAGES_TRIPS_UPDATE:
      return { ...state, trips : action.payload.trips };
      
    default:
      return { ...state };
  }
};
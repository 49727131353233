import React, { Component } from "react";
import { Row, Card, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { loginUser, loginUserAuto } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

import { NotificationManager } from "../../components/common/react-notifications";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.loginUserAuto(this.props.user, this.props.history);
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onUserLogin = (e) => {
    e.preventDefault();
    if (this.state.email !== "" && this.state.password !== "") {
      this.props.loginUser(this.state, this.props.history, this.onSubmitFailed);
    }
  };

  onSubmitFailed = (status, message) => {
    // alert(status+", "+ message);
    if (status) {
      NotificationManager.warning(
        message,
        status.toUpperCase(),
        7000,
        null,
        null,
        ""
      );
    }
  };

  render() {
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card login-form">
            <div className="position-relative image-side bg-none">
              <div className="border-radius-75 bg-white p-4">
                <Form onSubmit={this.onUserLogin}>
                  <Label className="form-group has-float-label mb-2 ">
                    <div>
                      <IntlMessages id="user.email" />
                    </div>
                    <Input
                      type="email"
                      name="email"
                      onChange={this.onChange}
                      value={this.state.email}
                      placeholder="email"
                    />
                  </Label>
                  <Label className="form-group has-float-label mb-3 ">
                    <div>
                      <IntlMessages id="user.password" />
                    </div>
                    <Input
                      type="password"
                      name="password"
                      onChange={this.onChange}
                      value={this.state.password}
                      placeholder="password"
                    />
                  </Label>
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn-shadow"
                      size="lg"
                    >
                      {this.props.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="user.login-button" />
                      )}
                    </Button>
                  </div>
                  <p>{this.props.error ? this.props.error.message : ""}</p>
                </Form>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  {" "}
                  <NavLink to={`/user/forgot-password`}>
                    <span style={{ color: "#145388" }}>
                      {/* Forgot Password */}
                      <IntlMessages id="user.forgot-password-question" />
                    </span>
                  </NavLink>
                </p>
                <p className=" mb-0" style={{ textAlign: "center" }}>
                  <IntlMessages id="user.login-with-your-crednetial" />

                  <br />
                  {/* <IntlMessages id="user.not-member" /> */}
                  <a
                    style={{ backgroundColor: "#fff" }}
                    href="https://www.cloudtel.qa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IntlMessages id="user.not-member" />
                  </a>
                </p>
                <p className="mb-0" style={{ textAlign: "center" }}>
                  <IntlMessages id="user.by-signin" />
                </p>
              </div>
            </div>

            <div className="form-side">
              <div className="bg-white border-radius-75 p-2">
                <img
                  src="/assets/company-logo.png"
                  alt="Cloud Telematics"
                  className="d-none d-xs-block mb-2"
                />

                <p className="d-inline text-black h2">
                  <IntlMessages id="user.qatar-first" />
                </p>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(mapStateToProps, {
  loginUser,
  loginUserAuto,
})(Login);

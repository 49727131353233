import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import alert from './alert';
import driverRanking from './driver-ranking';
import driverUtilization from './driver-utilization';
import driver from './driver';
import event from './event';
import fuel from './fuel';
import geofence from './geofence';
import vehicleSummary from './vehicle-summary';
import vehicleSummaryView from './summary-view';

import trip from './trip';
import vehicle from './vehicle';
import vehicleUtilization from './vehicle-utilization';
import idle from './idle';
import geofenceAlerts from './geofence-alerts';
import passengerChanges from './passenger-changes';
import logAccess from './log-access';

import driverTrip from './driver-trip';
import driverIdle from './driver-idle';
import driverAlerts from './driver-alerts';
import liveStatus from './live-status';

const Pages = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/trip`} />
            <Route path={`${match.url}/trip`} component={trip} />
            <Route path={`${match.url}/idle`} component={idle} />
            <Route path={`${match.url}/alert`} component={alert} />
            <Route path={`${match.url}/geofence-alerts`} component={geofenceAlerts} />
            <Route path={`${match.url}/driver-ranking`} component={driverRanking} />
            <Route path={`${match.url}/driver-utilization`} component={driverUtilization} />
            <Route path={`${match.url}/driver-alerts`} component={driverAlerts} />
            <Route path={`${match.url}/driver`} component={driver} />
            <Route path={`${match.url}/event`} component={event} />
            <Route path={`${match.url}/fuel`} component={fuel} />
            <Route path={`${match.url}/geofence`} component={geofence} />
            <Route path={`${match.url}/vehicle-summary`} component={vehicleSummary} />
            <Route path={`${match.url}/vehicle-summary-view`} component={vehicleSummaryView} />
            
            <Route path={`${match.url}/vehicle`} component={vehicle} />
            <Route path={`${match.url}/vehicle-utilization`} component={vehicleUtilization} />

            <Route path={`${match.url}/driver-trips`} component={driverTrip} />
            <Route path={`${match.url}/driver-idling`} component={driverIdle} />
            <Route path={`${match.url}/live`} component={liveStatus} />

            <Route path={`${match.url}/passengers`} component={passengerChanges} />
            <Route path={`${match.url}/log-access`} component={logAccess} />

            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Pages;

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "drill-downs/vehicles";

export const VehiclesGetDaySummary = (userToken, vehicleIds, startTime, endTime, offsetMinutes, pathWay) => {
    const criterion = { userToken, vehicleIds, startTime, endTime, offsetMinutes, pathWay};
    return axios
      .post(proxyServer + "/get-day-summary", { criterion })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


export const VehiclesGetDaySummaryReport2 = (userToken, vehicleIds, startTime, endTime, offsetMinutes, pathWay) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, offsetMinutes, pathWay};
  return axios
    .post(proxyServer + "/get-day-summary-report2", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehiclesGetDaySummaryWithPlaceFource = (userToken, vehicleIds, startTime, endTime) => {
  const placeName = true;
  const criterion = { userToken, vehicleIds, startTime, endTime, placeName};
  return axios
    .post(proxyServer + "/get-day-summary", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

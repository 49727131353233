import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import welcomeDash from './welcome';

const Dashboards = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/welcome`} />
             
            <Route path={`${match.url}/welcome`} component={welcomeDash} />

            <Redirect to="/error" />

        </Switch>
    </div>
);  
export default Dashboards;